.sms-btn {
  width: 110px;
  height: 40px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  margin-left: 10px;
  flex-shrink: 0;
}
