.logo-section {
  .logo {
    width: 140px;
    height: 45px;
    display: block;
    margin: 68px auto 50px;
  }

  .logo-title {
    text-align: center;
    font-size: 23px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 49px;
  }

  .logo-desc {
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    line-height: 25px;
  }
  .mt90 {
    margin-top: 90px;
  }
  .center {
    text-align: center;
  }
}
