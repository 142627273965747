.radio-input-section {
  font-size: 14px;
  padding: 0px 0px 13px;
  &.inline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .form-input-label {
    width: 100px;
    margin-bottom: 0px;
  }
  .radio-item {
    display: flex;
    align-items: center;
    line-height: 21px;
    padding: 3px;
    min-width: 80px;
    input {
      opacity: 0;
      position: absolute;
    }
    input[type="radio"] + label {
      display: flex;
    }
    input[type="radio"] + label:before {
      content: '';
      flex-shrink: 0;
      background: #ffffff;
      border-radius: 50%;
      border: 1px solid #b4b4b4;
      display: block;
      width: 17px;
      height: 17px;
      margin-right: 10px;
      top: 3px;
      position: relative;
      cursor: pointer;
      -webkit-transition: all 250ms ease;
      transition: all 250ms ease;
      box-sizing: border-box;
    }
    input[type="radio"]:checked + label:before {
      background-color: #000;
      border-color: #000;
      box-shadow: inset 0 0 0 3px #ffffff;
    }
  }
}
