.title-input-section {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0px 0px 13px;
  div:first-child {
    width: 100px;
  }
  .radio-item {
    width: 80px;
    display: flex;
    align-items: center;
    input {
      opacity: 0;
      position: absolute;
    }
    input[type="radio"] + label:before {
      content: '';
      background: #ffffff;
      border-radius: 50%;
      border: 1px solid #b4b4b4;
      display: inline-block;
      width: 17px;
      height: 17px;
      margin-right: 10px;
      top: 3px;
      position: relative;
      cursor: pointer;
      -webkit-transition: all 250ms ease;
      transition: all 250ms ease;
      box-sizing: border-box;
    }
    input[type="radio"]:checked + label:before {
      background-color: #000;
      border-color: #000;
      box-shadow: inset 0 0 0 3px #ffffff;
    }
  }
}

.logo-section {
  .logo {
    width: 140px;
    height: 45px;
    display: block;
    margin: 68px auto 50px;
  }

  .logo-title {
    text-align: center;
    font-size: 23px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 49px;
  }

  .logo-desc {
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    line-height: 25px;
  }
  .mt90 {
    margin-top: 90px;
  }
  .center {
    text-align: center;
  }
}
