* {
  margin: 0;
  padding: 0;
}
html, body {
  width: 100%;
  height: 100%;
}

#root {
}

.content {
  padding: 25px 40px;
}

.mb25 {
  margin-bottom: 25px;
}

.mr10 {
  margin-right: 10px;
}

.mt90 {
  margin-top: 90px;
}

.am-modal-button-group-h .am-modal-button:first-child {
  color: #aaaaaa!important;
}

.am-modal-button-group-h .am-modal-button {
  color: #000000!important;
}
