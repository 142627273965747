.form-picker {
  .am-picker-popup-item {
    color: #000000;
  }
}

.form-line-column-box {
  display: flex;
}

.input-line {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #EEEDEB;
  outline: none;
  border: 1px solid #EEEDEB;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: none;
  -webkit-appearance: none;
  .placeholder {
    color: #777;
  }
  &.required {
    border-color: #ef8885;
  }
  .down-icon {
    position: absolute;
    right: 10px;
  }
  .input-line-label {
    width: 51px;
    border-right: 1px solid #DADADA;
    display: flex;
    justify-content: center;
    margin-right: 5px;
  }
  .opacity-input {
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    background: transparent;
    border-color: transparent;
  }
}

.checkbox-line {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 0px;
  a {
    color: #000000;
    text-decoration: underline;
  }
  .checkbox-border {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #000000;
    margin-right: 7px;
    flex-shrink: 0;
    .am-icon {
      position: relative;
      top: -5px;
      left: -2px;
      transform: scale(1.2);
    }
  }
}

.mobile-prefix {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-right: 2vw;
  width: 20vw;
  height: 100%;
  border-right: 1px solid #999;

  .prefix-content {
    width: 60%;
    text-align: center;
  }

  .input-line-label {
    width: 10vw;
    margin-right: 0;
    padding-right: 7vw;
  }
}
