.form-input-item {
  margin-bottom: 13px;
}

.column-box {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  width: 100%;
  .column-box-item {
    width: 100%;
  }
}

.submit-btn {
  position: relative;
  width: 170px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #000000;
  border-radius: 20px;
  margin: 0 auto;
  &.disable {
    background: #aaaaaa;
  }
  a {
    color: #ffffff;
  }

  .button-loading-icon {
    position: absolute;
    top: 10px;
    right: 30px;
    // left: 50%;
    // transform: translate3d(-50%, -50%,0);
  }
}
