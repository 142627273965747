.container {
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
}

.loading-icon {
	position: absolute;
	width: 29vw;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
}
