.header-banner {
  width: 100%;
  display: block;
}

.home-tips {
  height: 37px;
  line-height: 37px;
  background: #000000;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
}
